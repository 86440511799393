import {
    Button,
    Container,
    Stack
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import ControlledGridStack from 'modules/dashboards/components/ControlledGridStack';
import { ControlledGridStackRef } from 'modules/dashboards/components/ControlledGridStack/ControlledGridStack';
import {
    DashboardsContext
} from 'modules/dashboards/interfaces/dashboards';
import {
    setCurrentProjectById
} from 'modules/projects/slices/projectsSlice';
import {
    useEffect,
    useRef,
    useState
} from 'react';
import {
    useLocation,
    useNavigate,
    useParams
} from 'react-router-dom';
import {
    fetchDefaultDashboard,
    saveDashboardMetadata
} from 'modules/dashboards/slices/dashboardsActions';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';

const DashboardsPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { defaultDashboard } = useAppSelector((state) => state.dashboards);
    const gridStackRef = useRef<ControlledGridStackRef>(null);

    const [items, setItems] = useState<Array<any>>([]);

    useEffect(() => {
        dispatch(fetchDefaultDashboard());
    }, [location, currentTenant]);

    useEffect(() => {
        if (!defaultDashboard) return;
        if (!defaultDashboard.metadata) return;

        setItems(defaultDashboard.metadata);
    }, [defaultDashboard]);

    const widgets = [
        {
            name: 'SiteMaxToolsMaintenanceExpiring',
            label: 'Equipment Maintenance',
            w: 6,
            h: 3,
        },
        {
            name: 'SiteMaxUsersRecordsExpiring',
            label: 'Users Records',
            w: 6,
            h: 3,
        },
        {
            name: 'SiteMaxRFIOverview',
            label: 'RFI Overview (Count/Days Impact/$K Impact)',
            export: true,
            w: 6,
            h: 8,
        },
        {
            name: 'SiteMaxPurchaseOrdersOverview',
            label: 'PO Overview',
            export: true,
            w: 6,
            h: 6,
        },
        {
            name: 'SiteMaxCompliance',
            label: 'Safety Reports Summary',
            export: true,
            h: 9,
            w: 6,
        },
        {
            name: 'SiteMaxProjectsCount',
            label: 'Projects Summary',
            export: true,
            w: 6,
            h: 7,
        }
    ];

    useEffect(() => {
        if (items.length) return;

        var widgetItems: any[] = [];
        widgets.forEach((widget) => {
            var id = 'widget_' + nanoid();
            widgetItems.push({
                id: id,
                title: 'Widget title',
                subtitle: 'Subtitle: ' + widget.name,
                position: {
                    id: id,
                    w: widget?.w || 6,
                    h: widget?.h || 9,
                },
                widget: widget
            });
        });
        setItems(widgetItems);
    }, []);

    const handleAddWidget = () => {};

    const handleSave = () => {
        const metadata = gridStackRef.current?.save();
        dispatch(saveDashboardMetadata(Object.assign({ ...defaultDashboard }, {
            metadata: metadata,
        })));
    };

    return <Container component="main" maxWidth={false}>
        {/* <Stack direction={'row'} spacing={2} sx={{ mt: 2 }}>
            <Button onClick={handleAddWidget}>Add Widget</Button>
            <Button onClick={handleSave}>Save</Button>
        </Stack> */}
        <DashboardsContext.Provider value={defaultDashboard}>
            <ControlledGridStack
                ref={gridStackRef}
                items={items}
                options={{
                    float: false,
                    sizeToContent: false,
                    cellHeight: '50px',
                }}
                sx={{
                    mt: 2,
                }}
            />
        </DashboardsContext.Provider>
    </Container>;
};

export default DashboardsPage;