import '@fontsource/ibm-plex-sans/300.css';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/500.css';
import '@fontsource/ibm-plex-sans/700.css';
import 'gridstack/dist/gridstack.min.css';
import {
    LIST_COST_CODE,
    LIST_DIVISION,
    LIST_PHASE, LIST_SCHEDULE_ITEM_STATUS, LIST_TASK_ITEM_STATUS,
    ListItemsQuery,
    ScheduleItemsQuery,
    TasksQuery
} from 'smx-components';
import {useLazyLoadQuery} from 'react-relay';

function AppLoader() {
    const newOriginScheduleItems = useLazyLoadQuery(ScheduleItemsQuery, {}, { fetchPolicy: 'network-only' });
    const newOriginTasks = useLazyLoadQuery(TasksQuery, {}, { fetchPolicy: 'network-only' });
    const newOriginPhase = useLazyLoadQuery(ListItemsQuery, {type: LIST_PHASE}, { fetchPolicy: 'network-only' });
    const newOriginDivision = useLazyLoadQuery(ListItemsQuery, {type: LIST_DIVISION}, { fetchPolicy: 'network-only' });
    const newOriginCostCode = useLazyLoadQuery(ListItemsQuery, {type: LIST_COST_CODE}, { fetchPolicy: 'network-only' });
    const newOriginScheduleItem = useLazyLoadQuery(ListItemsQuery, {type: LIST_SCHEDULE_ITEM_STATUS}, { fetchPolicy: 'network-only' });
    const newOriginTaskItem = useLazyLoadQuery(ListItemsQuery, {type: LIST_TASK_ITEM_STATUS}, { fetchPolicy: 'network-only' });

    return (
        <></>
    );
}

export default AppLoader;

