
import {
    useEffect,
    useRef,
} from 'react';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';
import SitemaxTable, { SitemaxTableActionItem, SitemaxTableHeader } from 'containers/SitemaxTable/SitemaxTable';
import { fetchUsers, unassignUsers } from '../../slices/projectsActions';
import { Button, Grid, Stack } from '@mui/material';
import { Add, DeleteSweepOutlined } from '@mui/icons-material';
import { BaseModalRef } from 'modals/BaseModal';
import UsersAssignModal from 'modules/projects/components/UsersAssignModal';

const ProjectUsersPage = () => {
    const dispatch = useAppDispatch();

    const { currentProject, users, loading } = useAppSelector((state) => state.projects);
    const userModalRef = useRef<BaseModalRef>(null);

    useEffect(() => {
        fetchData();
    }, [currentProject]);

    const fetchData = (search: string = '') => {
        dispatch(fetchUsers(search));
    };

    var headers: SitemaxTableHeader[] = [
        {
            field: 'name',
            headerName: 'Name',
        },
        {
            field: 'email',
            headerName: 'Email',
        },
        {
            field: 'phone',
            headerName: 'Phone',
        },
    ];

    const actions: Array<SitemaxTableActionItem> = [
        {
            title: 'Unassign from project',
            selectionRequired: true,
            onClick: (selected: Array<any>) => {
                let userIds = selected.map((item) => item.id);
                dispatch(unassignUsers({
                    users: userIds,
                    project: currentProject?.id || ''
                }));
                fetchData();
            },
            icon: <DeleteSweepOutlined fontSize="small" />,
        },
    ];

    return <>
        <Grid container sx={{
            bgcolor: 'white',
            paddingBottom: '10px',
        }}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={() => {
                            userModalRef?.current?.open();
                        }}
                        startIcon={<Add />}
                    >
                        Add User To Project
                    </Button>
                </Stack>
            </Grid>
        </Grid>
        <SitemaxTable
            headers={headers}
            rows={users || [] as Array<any>}
            getData={() => {
                fetchData();
            }}
            loading={loading}
            checkboxSelection={true}
            actions={actions}
        ></SitemaxTable>
        <UsersAssignModal
            ref={userModalRef}
            onSubmit={fetchData}
        />
    </>;
};

export default ProjectUsersPage;
