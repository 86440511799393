import '@fontsource/ibm-plex-sans/300.css';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/500.css';
import '@fontsource/ibm-plex-sans/700.css';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import {
    BrowserRouter,
    Route,
    Routes
} from 'react-router-dom';

import 'gridstack/dist/gridstack.min.css';

import DashboardsPage from 'modules/dashboards/pages/DashboardsPage';
import DocumentsSharedWithMePage from 'pages/DocumentsSharedWithMePage';
import DocumentsTrashPage from 'modules/documents/pages/DocumentsTrashPage';
import EmailUpdatePage from 'pages/EmailUpdatePage';
import IndexPage from 'pages/IndexPage';
import IntegrationsPage from 'pages/IntegrationsPage';
import NotFoundPage from 'pages/NotFoundPage';
import LegacyAccessPage from 'pages/LegacyAccessPage';
import PasswordUpdatePage from 'pages/PasswordUpdatePage';
import PhoneUpdatePage from 'pages/PhoneUpdatePage';
import ProfilePage from 'pages/ProfilePage';
import ProjectsPage from 'modules/projects/pages/ProjectsPage';
import ProjectUsersPage from 'modules/projects/pages/ProjectUsersPage';
import VerifyPage from 'pages/VerifyPage';
import ForgotPage from 'modules/welcome/pages/ForgotPage';
import RegisterPage from 'modules/welcome/pages/RegisterPage';
import SigninPage from 'modules/welcome/pages/SigninPage';
import SettingsPage from 'modules/settings/pages/SettingsPage';

import TopLayout from 'layout/TopLayout';

import ProtectedRoute from 'routes/ProtectedRoute';
import RedirectRoute from 'routes/RedirectRoute';
import UnauthorizedRoute from 'routes/UnauthorizedRoute';
import { removeSnackbar } from 'slices/snackbarsSlice';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

import theme from 'assets/theme';
import { SitemaxUIControllerProvider } from 'context';
import { LicenseInfo } from '@mui/x-license-pro';
import SharedDocumentsPage from 'modules/documents/pages/SharedDocumentsPage';
import DefaultDocumentsPage from 'modules/documents/pages/DefaultDocumentsPage';
import UsersPage from 'modules/users/pages/UsersPage';
import TimelinePage from './modules/timeline/pages/TimelinePage';
import {ConnectionProvider} from 'smx-components';
import CalendarPage from './modules/calendar/pages/CalendarPage';
import {useAxios} from './utilities/axios';
import React, {Suspense} from 'react';
import AppLoader from './AppLoader';
import {RelayEnvironmentProvider} from 'react-relay';
import useEnvironment from './hooks/useEnvironment';
import SchedulePage from './modules/schedule/pages/SchedulePage';

function App() {
    const dispatch = useAppDispatch();
    const environment = useEnvironment();

    const currentTenant = useAppSelector((state) => state.tenants.currentTenant);
    const accessToken = useAppSelector((state) => state.auth.accessToken);

    LicenseInfo.setLicenseKey('YOUR_LICENSE_KEY');
    const axios = useAxios(accessToken, currentTenant);

    return (
        <ConnectionProvider initPathApiVersion={'api/v1'} initInstanceAxios={axios}>
            <RelayEnvironmentProvider environment={environment}>
                {
                    accessToken && <Suspense fallback={''}>
                        <AppLoader/>
                    </Suspense>
                }
                <meta name="viewport" content="initial-scale=1, width=device-width"/>
                <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={3000}
                    preventDuplicate
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    action={(key) => (
                        <Button key={key} onClick={() => {
                            dispatch(removeSnackbar(key))
                        }} sx={{ color: 'white' }}>
                            Dismiss
                        </Button>
                    )}
                >
                    <BrowserRouter>
                        <SitemaxUIControllerProvider>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <Routes>
                                    <Route path="redirect" element={<RedirectRoute />} />
                                    <Route element={<UnauthorizedRoute />}>
                                        <Route path="signin" element={<SigninPage />} />
                                        <Route path="register" element={<RegisterPage />} />
                                        <Route path="register/:invitationCode" element={<RegisterPage />} />
                                        <Route path="forgot" element={<ForgotPage />} />
                                    </Route>
                                    <Route element={<ProtectedRoute />}>
                                        <Route element={<TopLayout />}>
                                            <Route path="/" element={<IndexPage />} />
                                            <Route path="profile">
                                                <Route index element={<ProfilePage />} />
                                                <Route path="verify" element={<VerifyPage />} />
                                                <Route path="email/update" element={<EmailUpdatePage />} />
                                                <Route path="password/update" element={<PasswordUpdatePage />} />
                                                <Route path="phone/update" element={<PhoneUpdatePage />} />
                                            </Route>
                                            <Route path="applications" element={<IntegrationsPage />} />
                                            <Route path="documents">
                                                <Route index element={<DefaultDocumentsPage />} />
                                                <Route path=":categoryId" element={<DefaultDocumentsPage />} />
                                                {/* <Route path="p/:teamId" element={<DefaultDocumentsPage />} />
                                            <Route path="p/:teamId/f/:categoryId" element={<DefaultDocumentsPage />} />
                                            <Route path="t/:teamId" element={<SharedDocumentsPage />} />
                                            <Route path="t/:teamId/f/:categoryId" element={<SharedDocumentsPage />} /> */}
                                            <Route path="trash" element={<DocumentsTrashPage />} />
                                            <Route path="shared" element={<SharedDocumentsPage />} />
                                            <Route path="shared/:categoryId" element={<SharedDocumentsPage />} />
                                                <Route path="trash" element={<DocumentsTrashPage />} />
                                                <Route path="shared" element={<DocumentsSharedWithMePage />} />
                                            </Route>
                                            <Route path="dashboards" element={<DashboardsPage />} />
                                            <Route path="projects">
                                                <Route index element={<ProjectsPage />} />
                                                <Route path=":projectId/dashboards" element={<DashboardsPage />} />
                                                <Route path=":projectId/users" element={<ProjectUsersPage />} />
                                            </Route>
                                            <Route path="settings" element={<SettingsPage />} />
                                            <Route path="timeline" element={<TimelinePage />} />
                                            <Route path="calendar" element={<CalendarPage />} />
                                            <Route path="schedule" element={<SchedulePage />} />
                                            <Route path="users" element={<UsersPage />} />
                                        </Route>
                                    </Route>
                                    <Route path="/access" element={<LegacyAccessPage />} />
                                    <Route path="*" element={<NotFoundPage />} />
                                </Routes>
                            </ThemeProvider>
                        </SitemaxUIControllerProvider>
                    </BrowserRouter>
                </SnackbarProvider>
            </RelayEnvironmentProvider>
        </ConnectionProvider>
    );
}

export default App;

