import React from 'react';
import {Button} from 'antd';

const ButtonAddScheduleItem = () => {
    const onCLickAdd = () => {
        console.log('click Add Button');
    };

    return <Button type={'default'} onClick={onCLickAdd} disabled>Add Schedule Item</Button>;
};

export default ButtonAddScheduleItem;
