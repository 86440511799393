import {Calendar, CalendarScaleType, Gant} from "smx-components";
import usePaddingTimeline from '../../../../hooks/usePaddingTimeline';
import {Carousel, Flex} from 'antd';
import ButtonScale from './buttonScale';
import ButtonAddScheduleItem from './buttonAddScheduleItem';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store';
import {ScheduleViewType} from '../../../../types';
import {useEffect, useMemo, useRef} from 'react';
import ButtonAddTask from './buttonAddTask';

const SchedulePage = () => {
    const paddingBottom = usePaddingTimeline();
    const activeCalendarPage = useSelector<RootState, CalendarScaleType>((state) => state.schedule.activeCalendarPage);
    const view = useSelector<RootState, ScheduleViewType>((state) => state.schedule.view);
    const carouselRef = useRef<any>();

    const gant = useMemo(() => <Gant paddingBottom={paddingBottom} />, []);
    const calendar = useMemo(() => <Calendar paddingBottom={paddingBottom} activePage={activeCalendarPage} />, [activeCalendarPage]);

    const getNumberSlide = () => {
        if(view === 'gant') return 0;
        if(view === 'calendar' && activeCalendarPage === 'list') return 1;
        if(view === 'calendar' && activeCalendarPage === 'agenda') return 2;
        if(view === 'calendar' && activeCalendarPage === 'day') return 3;
        return 0;
    };

    useEffect(() => {
        const newCurrentSlide = getNumberSlide();
        carouselRef.current.goTo(newCurrentSlide);
    }, [activeCalendarPage, view]);

    return <Flex vertical>
        <Flex justify={'space-between'}>
            {
                view === 'calendar' && <ButtonAddTask />
            }
            {
                view === 'gant' && <ButtonAddScheduleItem />
            }

            <ButtonScale />
        </Flex>
        <Carousel ref={carouselRef} dots={false} lazyLoad={'ondemand'} effect="fade" >
            <div>{gant}</div>
            <div>{view === 'calendar' && activeCalendarPage === 'list' && calendar}</div>
            <div>{view === 'calendar' && activeCalendarPage === 'agenda' && calendar}</div>
            <div>{view === 'calendar' && activeCalendarPage === 'day' && calendar}</div>
        </Carousel>
    </Flex>
}

export default SchedulePage;