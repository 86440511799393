import {
    Alert,
    AppBar,
    Avatar,
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from '@mui/material';
import Icon from '@mui/material/Icon';
import TenantsList from 'components/TenantsList';
import { NavigationItem } from 'interfaces/navigation';
import { Tenant } from 'interfaces/tenants';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAccount } from 'slices/accountActions';
import { logout } from 'slices/authActions';
import { fetchTenants } from 'slices/tenantsActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

import {
    navbar,
} from 'components/DefaultAppBar/styles';

import { setMiniSidenav, setTransparentNavbar, useSitemaxUIController } from 'context';
import useEmailPhoneUserVerification from '../../hooks/useEmailPhoneUserVerification';

const DefaultAppBar = ({ absolute = false, light = false, isMini = false }: any) => {
    const {isShowEmailVerificationAlert, isShowPhoneVerificationAlert} = useEmailPhoneUserVerification();
    const [navbarType, setNavbarType] = useState<any>();
    const [controller, dispatchUI] = useSitemaxUIController();
    const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const route = useLocation().pathname.split('/').slice(1);

    useEffect(() => {
        if (fixedNavbar) {
            setNavbarType('sticky');
        } else {
            setNavbarType('static');
        }

        // NOTE: A function that sets the transparent state of the navbar.
        const handleTransparentNavbar = () => {
            setTransparentNavbar(dispatchUI, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        };
        // The event listener that's calling the handleTransparentNavbar function when scrolling the window.
        window.addEventListener('scroll', handleTransparentNavbar);
        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();
        // Remove event listener on cleanup
        return () => window.removeEventListener('scroll', handleTransparentNavbar);
    }, [dispatchUI, fixedNavbar]);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleMiniSidenav = () => setMiniSidenav(dispatchUI, !miniSidenav);
    const { loading: loadingAuth } = useAppSelector((state) => state.auth);
    const { loading: loadingAccount, user } = useAppSelector((state) => state.account);
    const { mainMenu, settingsMenu } = useAppSelector((state) => state.navigationItems);
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const notificationBarEl = document.getElementById('notification-bar');

    useEffect(() => {
        dispatch(fetchAccount());
    }, []);

    const handleSingOut = async () => {
        dispatch(logout())
            .unwrap()
            .then(() => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleResolveVerificationAlert = () => {
        navigate('/profile/verify');
    };

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleNavigation = (event: React.MouseEvent<HTMLElement>, item: NavigationItem) => {
        navigate(item.url);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleSettings = (event: React.MouseEvent<HTMLElement>, item: NavigationItem) => {
        setAnchorElUser(null);
        navigate(item.url);
    };

    return <>
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme: any) => navbar(theme, { transparentNavbar, absolute, light })}
        >
            <Toolbar>
                <Icon
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleMiniSidenav}
                >
                    {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
                {/* <Box sx={{ px: 2 }}>
                    <Link to="/">
                        <img src="/images/sitemax-logo-2022.svg" height="40"></img>
                    </Link>
                </Box>
                <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
                    {mainMenu.map((item) => (
                        <Button
                            key={item.url}
                            onClick={(e) => handleNavigation(e, item)}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            {item.title}
                        </Button>
                    ))}
                </Stack> */}
                <Box sx={{ flexGrow: 0 }}>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar>{user?.initials}</Avatar>
                    </IconButton>
                    <Menu
                        sx={{ mt: 5 }}
                        elevation={1}
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <Box sx={{ width: 240 }}>
                            {settingsMenu.map((item) => (
                                <MenuItem key={item.url} onClick={(e) => handleSettings(e, item)}>
                                    <Typography textAlign="center">{item.title}</Typography>
                                </MenuItem>
                            ))}
                            <Divider />
                            <MenuItem key={'singout'} onClick={handleSingOut}>
                                <Typography textAlign="center">{'Sign Out'}</Typography>
                            </MenuItem>
                        </Box>
                    </Menu>
                </Box>
                {/* {
                    (loadingAuth || loadingAccount)
                    && <LinearProgress color="secondary" sx={{
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        width: "100%"
                    }} />
                } */}
            </Toolbar>
        </AppBar>
        <Drawer
            anchor="left"
            elevation={0}
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
        >
            <Box sx={{ width: 250 }}>
                <Box sx={{ px: 2, pt: 2, pb: 1 }}>
                    <Typography variant="overline">Workspaces</Typography>
                </Box>
                <TenantsList onClick={(tenant: Tenant) => setIsDrawerOpen(false)} />
            </Box>
        </Drawer>
        {/* {notificationBarEl && createPortal(
            (isShowEmailVerificationAlert || isShowPhoneVerificationAlert) &&
            <Box sx={{ pb: 2 }}>
                <Alert variant="outlined" severity="warning" action={
                    <Button color="inherit" size="small" onClick={handleResolveVerificationAlert}>Resolve</Button>
                }>
                    {
                        isShowEmailVerificationAlert &&
                        !isShowPhoneVerificationAlert &&
                        <>Your email is not verified</>
                    }
                    {
                        !isShowEmailVerificationAlert &&
                        isShowPhoneVerificationAlert &&
                        <>Your phone number is not verified</>
                    }
                    {
                        isShowEmailVerificationAlert &&
                        isShowPhoneVerificationAlert &&
                        <>Your email and phone number are not verified</>
                    }
                </Alert>
            </Box>,
            notificationBarEl
        )} */}
    </>;
}

export default DefaultAppBar;