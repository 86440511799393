import {Calendar} from "smx-components";
import usePaddingTimeline from '../../../../hooks/usePaddingTimeline';
import {Suspense} from 'react';

const CalendarPage = () => {
    const paddingBottom = usePaddingTimeline();

    return <Suspense fallback={'Loading ...'}>
        <Calendar paddingBottom={paddingBottom} />;
    </Suspense>;
}

export default CalendarPage;