import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    Skeleton,
    Typography
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { fetchUsers } from 'slices/usersActions';
import { assignUsers, fetchUsers as fetchProjectUsers } from 'modules/projects/slices/projectsActions';

interface Project {
    id?: string;
    name: string;
    number: string;
};

export interface UsersAssignModalProps {
    parent?: string;
    onSubmit?: () => void;
    onClose?: () => void;
};

const UsersAssignModal = (
    props: UsersAssignModalProps,
    ref: React.Ref<unknown>
) => {
    const modal = useRef<BaseModalRef>(null);
    const dispatch = useAppDispatch();
    const { users, loading, error } = useAppSelector((state) => state.users);
    const { currentProject } = useAppSelector((state) => state.projects);
    const { register, handleSubmit, reset } = useForm();
    const [isOpen, setIsOpen] = useState(false);
    const [project, setProject] = useState<Project | undefined>(undefined);
    const formId = 'project_user_assign_form_' + nanoid();
    const [selectedUserIds, setSelectedUserIds] = useState([]);

    useEffect(() => {
        if (isOpen) {
            reset();
        }
    }, [isOpen]);

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const onSubmit = () => {
        if (!currentProject) return;

        dispatch(assignUsers({
            users: selectedUserIds,
            project: currentProject.id || ' '
        }));
        dispatch(fetchProjectUsers(''));
        close();
    };

    const open = () => {
        dispatch(fetchUsers({search: ''}));
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    const handleCheckboxChange = (userId: string) => {
        let newSelectedUserIds = selectedUserIds.includes(userId as never) ? selectedUserIds.filter((id: string) => id !== userId) : [...selectedUserIds, userId];
        setSelectedUserIds(newSelectedUserIds as never);
      };

    return <>
        <BaseModal
            ref={modal}
            title={'Assign Users'}
            maxWidth={'xs'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={close}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        form={formId}
                        loading={loading}
                        color="primary"
                        disableElevation
                    >
                        Assign
                    </LoadingButton>
                </>
            }
        >
            {(isOpen) ?
                <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                    {users && users.map(user => (
                        <Grid item xs={12} sm={12} md={12} key={user.id}>
                            <Card variant="outlined" sx={{ minWidth: 275, marginBottom: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        {user.full_name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {user.email}
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedUserIds.includes(user.id as never)}
                                                onChange={() => handleCheckboxChange(user.id)}
                                            />
                                        }
                                        label="Select"
                                        sx={{ marginTop: 1 }}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                </form>
                : <Skeleton variant="rounded" width={396} height={56}/>
            }
        </BaseModal>
    </>;
};

export default forwardRef(UsersAssignModal);